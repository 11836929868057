import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import "./Home.scss";
import FAQ from "../components/FAQ/FAQ";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router-dom";

const Home = () => {
  let navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="elevatorPitch">
        <h1 className="title">AIcruit</h1>
        <span className="description">
          Quality coding platform to conduct your technical interviews powered
          by GPT-4
        </span>
      </div>
      <div className="bicontainer">
        <span className="bicontainerTitle">Platforms</span>
        <div className="containerBicontainer">
          <div className="indcontainer">
            <span className="platform">
              Website
              <span className="disclaimer">Available Now!</span>
            </span>
            <span className="platformDescription">
              <ul>
                <li>Real-life interaction with an AI avatar</li>
                <li>Comprehensive interview analysis with GPT-4</li>
                <li>Email updates to stay informed</li>
                <li>Robust plagiarism detection and toggle</li>
                <li>Personalized context for each position</li>
                <li>Dynamic split between theory and technical questions</li>
              </ul>
            </span>
            <div className="button" onClick={() => navigate("/register")}>
              <span className="buttonText">View</span>
            </div>
          </div>
          <div className="indcontainer">
            <span className="platform">
              CLI
              <span className="disclaimer">Coming Soon!</span>
            </span>
            <span className="platformDescription">
              <ul>
                <li>Advanced interview analysis powered by GPT-4</li>
                <li>Email updates for interview progress and results</li>
                <li>Plagiarism detection ensures integrity</li>
                <li>Customizable context for each position</li>
                <li>Seamless split between theory and technical questions</li>
                <li>Minor hints provided for a smooth interview experience</li>
              </ul>
            </span>
            <div className="button">
              <span className="buttonText">Download</span>
            </div>
          </div>
        </div>
      </div>
      <ScrollAnimation
        animateIn="animate__zoomIn"
        animateOut="animate__zoomOutDown"
      >
        <div className="animateContainer">
          <span className="bigText">AI-Powered Interviews</span>
          <span className="smallText">
            Transform your hiring process with advanced AI technology, enabling
            precise and data-driven technical interviews in seconds.
          </span>
        </div>
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__zoomIn"
        animateOut="animate__zoomOutDown"
      >
        <div className="animateContainer">
          <span className="bigText">Customizable & Targeted</span>
          <span className="smallText">
            Tailor each interview to match your position's unique requirements,
            providing context and asking customized questions to assess
            candidates accurately.
          </span>
        </div>
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__zoomIn"
        animateOut="animate__zoomOutDown"
      >
        <div className="animateContainer">
          <span className="bigText">Candidate Friendly</span>
          <span className="smallText">
            Create a positive candidate journey with AIcruit's intuitive
            interface, offering valuable insights, fair evaluations, and
            supportive hints to help candidates shine.
          </span>
        </div>
      </ScrollAnimation>
      <div className="faqContainer">
        <span className="faqTitle">FAQ</span>
        <FAQ />
      </div>
      <div className="interestContainer">
        <span className="bigInterestText">
          Level up your technical interviews
        </span>
        <span className="smallInterestText">
          Experience the future of interviewing and simplify talent acquisition
          with AIcruit
        </span>
        <div className="joinNowButton">
          <span className="joinNowButtonText">Join now!</span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
