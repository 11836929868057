import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

// TODO: Generate sitemap

const Footer = () => {
  let navigate = useNavigate();
  const [user, setUser] = useState(false);
  const auth = getAuth();
  const [status, setStatus] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [trialDate, setTrialDate] = useState();
  const [planPeriod, setPlanPeriod] = useState();

  const calculateEndDate = (startDate, period) => {
    const endDate = new Date(startDate.seconds * 1000);

    switch (period) {
      case "1 month":
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case "6 months":
        endDate.setMonth(endDate.getMonth() + 6);
        break;
      case "1 year":
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
      default:
        // Handle invalid plan period
        break;
    }

    return endDate;
  };

  useEffect(() => {
    const fetchStatus = () => {
      if (auth.currentUser) {
        const docRef = doc(db, "users", auth.currentUser.uid);
        getDoc(docRef)
          .then((docSnap) => {
            if (docSnap.exists()) {
              setStatus(docSnap.data().payment);
              setPaymentDate(docSnap.data().paymentDate);
              setTrialDate(docSnap.data().oneWeekFreeTrial);
              setPlanPeriod(docSnap.data().planPeriod);
            } else {
              // Handle the case where the document doesn't exist
            }
          })
          .catch((error) => {
            // Handle any Firebase fetch errors here
            console.error("Error fetching document:", error);
          });
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchStatus(); // Fetch user status after authentication state changes
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe the auth state change listener when component unmounts
    };
  }, [auth]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      }
    });
  }, [auth]);

  const goToHomePage = () => {
    window.location.href = window.location.origin;
  };

  return (
    <div className="footer">
      <div className="rowFooter">
        <div className="leftFooter">
          <span onClick={() => goToHomePage()}>AIcruit</span>
        </div>
        <div className="rightFooter">
          {!user ? (
            <>
              <span onClick={() => navigate("/login")}>Login</span>
              <span onClick={() => navigate("/register")}>Register</span>
            </>
          ) : (
            <>
              {trialDate !== undefined &&
              new Date().toISOString() <= trialDate ? (
                <>
                  <span onClick={() => navigate("/dashboard")}>Dashboard</span>
                  <span
                    onClick={() => {
                      signOut(auth);
                      navigate("/");
                    }}
                  >
                    Sign Out
                  </span>
                </>
              ) : (
                <>
                  {paymentDate &&
                  status &&
                  (new Date(paymentDate.seconds * 1000) > new Date() ||
                    (new Date() > calculateEndDate(paymentDate, planPeriod) &&
                      status === true)) ? (
                    <>
                      <span onClick={() => navigate("/dashboard")}>
                        Dashboard
                      </span>
                      <span
                        onClick={() => {
                          signOut(auth);
                          navigate("/");
                        }}
                      >
                        Sign Out
                      </span>
                    </>
                  ) : (
                    <>
                      <span onClick={() => navigate("/payment")}>Payment</span>
                    </>
                  )}
                </>
              )}
              <span onClick={() => navigate("/contact")}>Contact</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
