import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const Login = () => {
  let navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    auth.languageCode = auth.useDeviceLanguage();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/dashboard");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        Swal.fire(
          "Error " + errorCode,
          errorMessage + " There was an error with the email: " + email,
          "error"
        );
      });
  };

  const submitLogin = () => {
    signInWithEmailAndPassword(
      getAuth(),
      emailRef.current.value,
      passwordRef.current.value
    )
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        navigate("/dashboard");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        if (errorCode === "auth/user-not-found") {
          Swal.fire(
            "User not found",
            "A user was not found with this email: " + email,
            "error"
          );
        }
      });
  };

  return (
    <div className="container">
      <span className="loginTitle">Login</span>
      <div className="loginButton" onClick={() => handleLogin()}>
        <img
          src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
          alt="Google Icon"
          className="googleImage"
        />
        <span className="loginButtonText">Continue with Google</span>
      </div>
      <span className="orText">or</span>
      <input ref={emailRef} type="email" placeholder="Email" />
      <input ref={passwordRef} type="password" placeholder="Password" />
      <button onClick={() => submitLogin()}>Login</button>
      <span className="dontHaveAccount">
        Don't have an account? <a href="/register">Register</a>
      </span>
    </div>
  );
};

export default Login;
