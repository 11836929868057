import { useRef } from "react";
import Header from "../components/Header/Header";
import { FaPaperPlane } from "react-icons/fa6";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./Contact.scss";

const Contact = () => {
  const nameRef = useRef(null);
  const subjectRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const handleSend = () => {
    window.open(
      `mailto:aicruit@outlook.com?subject=${encodeURIComponent(
        subjectRef.current.value
      )}&body=${encodeURIComponent(
        nameRef.current.value
      )} (${encodeURIComponent(emailRef.current.value)}): ${encodeURIComponent(
        messageRef.current.value
      )}`
    );
    Swal.fire("Success!", "Your email has been sent!", "success");
    nameRef.current.value = null;
    subjectRef.current.value = null;
    emailRef.current.value = null;
    messageRef.current.value = null;
  };

  return (
    <>
      <Header />
      <div className="contactContainer">
        <div className="leftContactContainer">
          <span className="contactTitle">Contact</span>
          <span className="contactDescription">
            Let us know if you have any questions or would like a feature to be
            implemented.
          </span>
        </div>
        <div className="rightContactContainer">
          <div>
            <span className="inputField">Name</span>
            <input ref={nameRef} type="text" placeholder="John Doe" />
          </div>
          <div>
            <span className="inputField">Email</span>
            <input ref={emailRef} type="text" placeholder="johndoe@gmail.com" />
          </div>
          <div>
            <span className="inputField">Subject</span>
            <input ref={subjectRef} type="text" placeholder="Feature Request" />
          </div>
          <div>
            <span className="inputField">Message</span>
            <textarea
              ref={messageRef}
              type="text"
              placeholder="Enter message here..."
            />
          </div>
          <div className="sendButton" onClick={() => handleSend()}>
            <FaPaperPlane />
            <span className="sendButtonText">Send</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
