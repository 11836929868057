import Question from "./Question/Question";
import data from "./questionData";

const FAQ = () => {
  return (
    <div style={{ overflow: "hidden", marginBottom: "5rem" }}>
      {data.map((sample) => (
        <Question {...sample} key={sample.question} />
      ))}
    </div>
  );
};

export default FAQ;
