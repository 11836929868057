import Home from "./Home/Home";
import Register from "./Register/Register";
import Login from "./Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import Interview from "./Interview/Interview";
import Dashboard from "./Dashboard/Dashboard";
import Contact from "./Contact/Contact";
import Payment from "./components/Payment/Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

function App() {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(false);
  const [status, setStatus] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [paymentDate, setPaymentDate] = useState(null);
  const [trialDate, setTrialDate] = useState();

  const retrieveSubscriptionStatus = async () => {
    if (getAuth().currentUser) {
      const docRef = doc(db, "users", getAuth().currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setStatus(docSnap.data().payment);

        if (docSnap.data().paymentDate !== undefined) {
          setPaymentDate(docSnap.data().paymentDate);
        }
        if (docSnap.data().oneWeekFreeTrial !== undefined) {
          setTrialDate(docSnap.data().oneWeekFreeTrial);
        }
      } else {
        // docSnap.data() will be undefined in this case
      }
    }
  };
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(true);
      } else {
        setCurrentUser(false);
      }
    });

    retrieveSubscriptionStatus();
  }, [auth]);
  const currentTime = new Date().toISOString();

  if (currentUser && status === false && trialDate !== undefined) {
    if (currentTime <= trialDate) {
      // User is within the oneWeekFreeTrial period, allow access to the dashboard
      return (
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/join-interview" element={<Interview />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
        </Elements>
      );
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {!currentUser && status === false ? (
            <>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
            </>
          ) : currentUser &&
            status === false &&
            (new Date(paymentDate?.seconds * 1000) <= new Date() ||
              currentTime > trialDate) ? (
            <Route path="/payment" element={<Payment />} />
          ) : (
            <Route path="/dashboard" element={<Dashboard />} />
          )}
          <Route path="/join-interview" element={<Interview />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
