import Header from "./components/Header";
import Footer from "../components/Footer/Footer";
import { FaCheck, FaPlus, FaRegClock } from "react-icons/fa6";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";

import "./Dashboard.scss";
import { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import Toggle from "react-toggle";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactLoading from "react-loading";
import toast, { Toaster } from "react-hot-toast";
import { pdfjs } from "react-pdf";

import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Dashboard = () => {
  const [interviewData, setInterviewData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [plagiarismDetection, setPlagiarismDetection] = useState(false);
  const interviewCodeRef = useRef(null);
  const secretPhraseRef = useRef(null);
  const positionRef = useRef(null);
  const descriptionRef = useRef(null);
  const questionSplitRef = useRef(null);
  const jobDescriptionRef = useRef(null);
  const jobPositionRef = useRef(null);
  let resumeList = { 0: [] };

  function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
      // BOM sequence
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
  }
  function getParagraphs(content) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    let paragraphs = "";

    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
      let fullText = "";
      const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
      for (let j = 0, len2 = textsXml.length; j < len2; j++) {
        const textXml = textsXml[j];
        if (textXml.childNodes) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
      if (fullText) {
        paragraphs += fullText;
      }
    }
    return paragraphs;
  }

  const notify = (message) =>
    toast.success(message, {
      style: {
        background: "#333",
        color: "#fff",
      },
    });

  const extractTextFromPDF = async (pdfData) => {
    try {
      const loadingTask = pdfjs.getDocument(pdfData);
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;

      const textContents = [];
      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map((item) => item.str).join(" ");
        textContents.push(pageText);
      }

      return textContents.join("\n");
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      return null;
    }
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    for (const file of files) {
      if (file.type === "application/pdf") {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const pdfData = e.target.result;

          try {
            const text = await extractTextFromPDF(pdfData);
            if (text) {
              const resumeArr = resumeList[0] || [];
              resumeArr.push(text);
              resumeList[0] = resumeArr;
            } else {
              console.error("Failed to extract text from PDF");
            }
          } catch (error) {
            console.error("Error extracting text from PDF:", error);
          }
        };

        reader.readAsDataURL(file);
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          const paragraphs = getParagraphs(content);
          console.log("PARAGRAPHS >>>> " + paragraphs);
          const resumeArr = resumeList[0] || [];
          resumeArr.push(paragraphs);
          resumeList[0] = resumeArr;
        };

        reader.onerror = (err) => console.error(err);

        reader.readAsBinaryString(file);
      }
    }
    notify(`Uploaded ${files.length} resumes`);
  };

  const copyToClipboard = async (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Clipboard write failed:", error);
      });
  };

  const createInterview = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: getAuth().currentUser.email,
      position: positionRef.current.value,
      plag_decision: "n",
      context: descriptionRef.current.value,
      question_split: questionSplitRef.current.value,
      uid: getAuth().currentUser.uid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://aicruit-api-a8f6c1db1758.herokuapp.com/create-interview",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setLoading(false);
        result = JSON.parse(result);
        const interviewLink =
          "https://aicruit.co/join-interview?code=" + result["interview_code"];
        copyToClipboard(interviewLink);
        setShowModal(false);
        Swal.fire({
          title: "Interview Created",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Copy Interview Code",
          denyButtonText: `Copy Secret Phrase`,
          icon: "success",
          text:
            "Here is the link for the candidate to join the interview https://aicruit.co/join-interview?code=" +
            result["interview_code"] +
            ". A secret phrase has also been generated for you to end an interview prematurely. Store the secret phrase and interview code somewhere safe to end the interview. The secret phrase is " +
            result["secret_phrase"] +
            ". The interview link has already been copied to your clipboard. You may keep this modal open if you'd like.",
        }).then((result) => {
          if (result.isConfirmed) {
            copyToClipboard(result["interview_code"]);
          } else if (result.isDenied) {
            copyToClipboard(result["secret_phrase"]);
          }
        });
      })
      .catch((err) => {
        console.error(err);
        Swal.fire(
          "Incorrect Input!",
          "An incorrect input has been provided. Please try again!",
          "error"
        );
      });
  };

  const endInterview = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      interview_code: interviewCodeRef.current.value,
      secret_phrase: secretPhraseRef.current.value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://aicruit-api-a8f6c1db1758.herokuapp.com/end-interview",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowModalTwo(false);
        result = JSON.parse(result);
        result.status === "Success!"
          ? Swal.fire("Interview Ended", result.message, "success")
          : Swal.fire(result.status, result.message, "error");
      })
      .catch(() =>
        Swal.fire(
          "Incorrect Input!",
          "An incorrect input has been provided. Please try again!",
          "error"
        )
      );
  };

  const sendResumes = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      job_position: jobPositionRef.current.value,
      job_description: jobDescriptionRef.current.value,
      resumes: resumeList,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (resumeList.length < 1) {
      Swal.fire(
        "Incorrect Input!",
        "You have uploaded 0 resumes. Please try again and upload a resume.",
        "error"
      );
    } else {
      fetch(
        "https://aicruit-api-a8f6c1db1758.herokuapp.com/resume-scanner",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          setLoading(false);
          setShowModalThree(false);
          const json_result = JSON.parse(result);
          if (json_result.status === "Success!") {
            const modifiedMessage = json_result.message.replace(
              /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
              '"$2": '
            );
            const parsedMessage = JSON.parse(modifiedMessage);

            let text = "<span><br/>";
            for (let i = 0; i < Object.keys(parsedMessage).length; i++) {
              text +=
                parsedMessage[i].name + " Email: " + parsedMessage[i].email;
              text += "<br/>";
            }
            text += "</span>";
            Swal.fire({
              title: "Analysis Results",
              customClass: {
                validationMessage: "text-align: center",
              },
              html:
                "<p>Applicants that are best suited for the job: <br/>" +
                text +
                "<br/> Upon clicking 'Continue', you can send links to AI-conducted interviews by clicking the 'Create New Interview' button.</p>",
              showCancelButton: true,
              confirmButtonText: "Continue",
              cancelButtonColor: "#ff0000",
              icon: "success",
            });
          } else {
            Swal.fire(json_result.status, json_result.message, "error");
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire(
            "Incorrect Input!",
            "An incorrect input has been provided. Please try again!",
            "error"
          );
        });
    }
  };

  useEffect(() => {
    const fetchInterviewData = async () => {
      const docRef = doc(db, "interview_codes", getAuth().currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setInterviewData(docSnap.data());
      }
    };

    fetchInterviewData();
  }, []);

  return (
    <>
      <Header />
      <div className="dashboardContainer">
        <Toaster />
        <div className="dashboardHeader">
          <span className="dashboardTitle">Dashboard</span>
          <div className="dashboardBtnContainer">
            <div className="newInterviewBtn" onClick={() => setShowModal(true)}>
              <FaPlus />
              <span className="newInterviewText">Create New Interview</span>
            </div>
            <div
              className="resumeScannerBtn"
              onClick={() => setShowModalThree(true)}
            >
              <HiMiniDocumentDuplicate />
              <span className="resumeScannerText">Resume Screener</span>
            </div>
          </div>
        </div>
        <div className="interviewTitles">
          <span className="interviewTitle">Date Joined</span>
          <span className="interviewTitle">Code</span>
          <span className="interviewTitle">Interview Status</span>
        </div>
        {Object.keys(interviewData).length > 0 ? (
          interviewData &&
          interviewData.codes &&
          interviewData.codes.map((data) => (
            <div className="row" key={data.code}>
              <span className="value">
                {new Date(
                  data.time_created.seconds * 1000
                ).toLocaleDateString()}
              </span>
              <span className="value">{data.code}</span>
              {data.status === "In Progress" ? (
                <FaRegClock fill="#FFBF00" />
              ) : (
                <FaCheck fill="#34b233" />
              )}
              {/* Add more elements here for any additional interview titles */}
              {data.status === "In Progress" ? (
                <div
                  className="endInterviewBtn"
                  onClick={() => setShowModalTwo(true)}
                >
                  <span className="endInterviewText">End Interview</span>
                </div>
              ) : (
                <div className="endInterviewBtn--disabled">
                  <span className="endInterviewText">End Interview</span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="noResultsContainer">
            No interviews have been created/joined. You can create an interview
            by clicking &quot;Create New Interview&quot;
          </div>
        )}
      </div>
      <Footer />
      {loading && (
        <div className="loadingContainer">
          <ReactLoading
            type={"bubbles"}
            color={"#ff8c00"}
            height={667}
            width={375}
          />
        </div>
      )}
      {showModal && (
        <div className="modal">
          <div className="modalContainer">
            <div className="inputContainer">
              <span className="inputText">Position</span>
              <input
                type="text"
                placeholder="Software Developer"
                ref={positionRef}
              />
            </div>
            <div className="inputContainer">
              <span className="inputText">Work Description</span>
              <input
                type="text"
                placeholder="Working with the Google Maps API"
                ref={descriptionRef}
              />
            </div>
            <div className="inputContainer">
              <span className="inputText">
                Question Split (technical:conceptual)
              </span>
              <input type="text" placeholder="70:30" ref={questionSplitRef} />
            </div>
            {/* <div className="inputContainer" style={{ flexDirection: "row" }}>
              <span className="inputText">Plagiarism Detection?</span>
              <Toggle
                defaultChecked={plagiarismDetection}
                onChange={() => setPlagiarismDetection(!plagiarismDetection)}
              />
            </div> */}
            <div className="btnContainer">
              <div className="cancelButton" onClick={() => setShowModal(false)}>
                <span className="cancelText">Cancel</span>
              </div>
              <div className="continueButton" onClick={() => createInterview()}>
                <span className="continueText">Continue</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModalTwo && (
        <div className="modal">
          <div className="modalContainer">
            <div className="inputContainer">
              <span className="inputText">Interview Code</span>
              <input
                type="text"
                placeholder="rh2Xh6mPsLUi89cV"
                ref={interviewCodeRef}
              />
            </div>
            <div className="inputContainer">
              <span className="inputText">Secret Phrase</span>
              <input
                type="text"
                placeholder="KQo8XH0bpCIGjuZo"
                ref={secretPhraseRef}
              />
            </div>
            <div className="btnContainer">
              <div
                className="cancelButton"
                onClick={() => setShowModalTwo(false)}
              >
                <span className="cancelText">Cancel</span>
              </div>
              <div className="continueButton" onClick={() => endInterview()}>
                <span className="continueText">Continue</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModalThree && (
        <div className="modal">
          <div className="modalContainer">
            <span className="modalTitle">Resume Scanner</span>
            <span className="modalDescription">
              Conduct a technical screening using AI by uploading resumes of
              your applicants and providing a job description. Once you've
              uploaded all the resume PDFs, click "Analyze" for results.
            </span>
            <div className="inputContainer">
              <span className="inputText">Position</span>
              <input
                type="text"
                placeholder="Product Manager at Google"
                ref={jobPositionRef}
              />
            </div>
            <div className="inputContainer">
              <span className="inputText">Job Description</span>
              <input
                type="text"
                placeholder="Defining product goals and strategies, Leading cross-functional teams, Conducting market research and customer feedback analysis"
                ref={jobDescriptionRef}
              />
            </div>
            <input
              hidden
              type="file"
              accept=".pdf, .docx"
              id="fileUpload"
              onChange={handleFileChange}
              multiple
            />
            {/* <div style={{ display: "none" }} ref={pdfRef}>
              {pdfBase64 && (
                <Document
                  file={pdfBase64}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              )}
            </div> */}
            <div className="btnContainer">
              <div
                className="cancelButton"
                onClick={() => setShowModalThree(false)}
              >
                <span className="cancelText">Cancel</span>
              </div>
              <div
                className="uploadPDFBtn"
                onClick={() => {
                  document.getElementById("fileUpload").click();
                }}
              >
                <span className="uploadPDFText">Upload PDF(s)</span>
              </div>
              <div
                className="analyzeBtn"
                onClick={() => {
                  sendResumes();
                }}
              >
                <span className="analyzeText">Analyze</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
