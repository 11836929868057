import { useState } from "react";
import "./Question.scss";
import { HiOutlineMinus } from "react-icons/hi";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { HiPlus } from "react-icons/hi";

const Question = ({ question, answer }) => {
  const [show, setShow] = useState(false);

  return (
    <ScrollAnimation animateIn="animate__slideInLeft">
      <div style={{ marginTop: "1.837rem", marginBottom: "3.675rem" }}>
        <div className="questionContainer">
          <span onClick={() => setShow(!show)} className="question">
            {question}
          </span>
          {show ? (
            <HiOutlineMinus
              style={{ height: "3.675rem", cursor: "pointer" }}
              onClick={() => setShow(!show)}
            />
          ) : (
            <HiPlus
              style={{ height: "3.675rem", cursor: "pointer" }}
              onClick={() => setShow(!show)}
            />
          )}
        </div>
        <div className="answer">{show && <span>{answer}</span>}</div>
      </div>
    </ScrollAnimation>
  );
};

export default Question;
