const data = [
  {
    question: "How does AIcruit benefit interviewers?",
    answer:
      "AIcruit provides AI-driven insights, contextual information, email updates, plagiarism detection, and streamlined interviews with hints. It empowers interviewers with comprehensive analysis and a tailored experience.",
  },
  {
    question: "What advantages does AIcruit offer to candidates?",
    answer:
      "AIcruit offers in-depth analysis, fair evaluation, customizable context, and minor hints to support candidates throughout the interview. It helps candidates improve their skills and provides a positive experience.",
  },
  {
    question:
      " Is AIcruit suitable for different industries and technical roles?",
    answer:
      "Absolutely! AIcruit adapts to various industries and technical roles. It allows customization based on specific requirements, making it suitable for software development, data science, and other technical fields.",
  },
  {
    question: "How secure and reliable is AIcruit?",
    answer:
      "AIcruit prioritizes security and reliability. It ensures confidentiality, utilizes plagiarism detection, and undergoes regular testing and updates to provide a secure and stable experience.",
  },
  {
    question:
      "Can AIcruit integrate with existing interview processes or tools?",
    answer:
      "Yes, AIcruit offers integration capabilities with APIs and documentation. It seamlessly integrates with existing interview processes and tools, enhancing the overall workflow.",
  },
];

export default data;
