import { useNavigate } from "react-router-dom";
import "./Header.scss";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { FaBars, FaXmark } from "react-icons/fa6";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const Header = () => {
  let navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [status, setStatus] = useState();
  const [paymentDate, setPaymentDate] = useState();
  const [trialDate, setTrialDate] = useState();
  const [planPeriod, setPlanPeriod] = useState();
  const auth = getAuth();

  const calculateEndDate = (startDate, period) => {
    const endDate = new Date(startDate.seconds * 1000);

    switch (period) {
      case "1 month":
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case "6 months":
        endDate.setMonth(endDate.getMonth() + 6);
        break;
      case "1 year":
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
      default:
        // Handle invalid plan period
        break;
    }

    return endDate;
  };

  useEffect(() => {
    const fetchStatus = () => {
      if (auth.currentUser) {
        const docRef = doc(db, "users", auth.currentUser.uid);
        getDoc(docRef)
          .then((docSnap) => {
            setStatus(docSnap.data().payment);
            setPaymentDate(docSnap.data().paymentDate);
            setTrialDate(docSnap.data().oneWeekFreeTrial);
            setPlanPeriod(docSnap.data().planPeriod);
          })
          .catch((error) => {
            // Handle any Firebase fetch errors here
            console.error("Error fetching document:", error);
          });
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchStatus(); // Fetch user status after authentication state changes
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe the auth state change listener when component unmounts
    };
  }, [auth]);

  const sidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <div className="header">
      <div className="leftHeader">
        <span>AIcruit</span>
        {document.innerWidth <= 450 ? (
          <FaBars onClick={sidebar} id="menuIcon" />
        ) : (
          <></>
        )}
        {showSidebar ? (
          <>
            <div className="sidebar">
              <div style={{ background: "transparent" }}>
                {showSidebar ? (
                  <FaXmark
                    style={{ marginRight: "45vw", background: "transparent" }}
                    className="menuIcon"
                    onClick={sidebar}
                  />
                ) : (
                  <FaBars className="menuIcon" onClick={sidebar} />
                )}
              </div>
              <div className="optionsContainer">
                {!auth.currentUser ? (
                  <>
                    <span
                      className="headerOption"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </span>
                    <span
                      className="headerOption"
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </span>
                    <span
                      className="headerOption"
                      onClick={() => navigate("/contact")}
                    >
                      Contact
                    </span>
                  </>
                ) : (
                  <>
                    {trialDate !== undefined &&
                    new Date().toISOString() <= trialDate ? (
                      <>
                        <span
                          className="headerOption"
                          onClick={() => navigate("/dashboard")}
                        >
                          Dashboard
                        </span>
                        <span
                          className="headerOption"
                          onClick={() => {
                            signOut(auth);
                            navigate("/");
                          }}
                        >
                          Sign Out
                        </span>
                      </>
                    ) : (
                      <>
                        {paymentDate &&
                        status === true &&
                        new Date(trialDate.seconds * 1000) < new Date() ? (
                          <>
                            <span
                              className="headerOption"
                              onClick={() => navigate("/dashboard")}
                            >
                              Dashboard
                            </span>
                            <span
                              className="headerOption"
                              onClick={() => {
                                signOut(auth);
                                navigate("/");
                              }}
                            >
                              Sign Out
                            </span>
                          </>
                        ) : paymentDate &&
                          status &&
                          new Date(trialDate.seconds * 1000) > new Date() ? (
                          <>
                            <span
                              className="headerOption"
                              onClick={() => navigate("/dashboard")}
                            >
                              Dashboard
                            </span>
                            <span
                              className="headerOption"
                              onClick={() => {
                                signOut(auth);
                                navigate("/");
                              }}
                            >
                              Sign Out
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="headerOption"
                              onClick={() => navigate("/payment")}
                            >
                              Payment
                            </span>
                          </>
                        )}
                      </>
                    )}
                    <span
                      className="headerOption"
                      onClick={() => navigate("/contact")}
                    >
                      Contact
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="rightHeader">
        {!auth.currentUser ? (
          <>
            <span className="headerOption" onClick={() => navigate("/login")}>
              Login
            </span>
            <span
              className="headerOption"
              onClick={() => navigate("/register")}
            >
              Register
            </span>
          </>
        ) : (
          <>
            {trialDate !== undefined &&
            new Date().toISOString() <= trialDate ? (
              <>
                <span
                  className="headerOption"
                  onClick={() => navigate("/dashboard")}
                >
                  Dashboard
                </span>
                <span
                  className="headerOption"
                  onClick={() => {
                    signOut(auth);
                    navigate("/");
                  }}
                >
                  Sign Out
                </span>
              </>
            ) : (
              <>
                {paymentDate &&
                status &&
                (new Date(paymentDate.seconds * 1000) > new Date() ||
                  (new Date() > calculateEndDate(paymentDate, planPeriod) &&
                    status === true)) ? (
                  <>
                    <span
                      className="headerOption"
                      onClick={() => navigate("/dashboard")}
                    >
                      Dashboard
                    </span>
                    <span
                      className="headerOption"
                      onClick={() => {
                        signOut(auth);
                        navigate("/");
                      }}
                    >
                      Sign Out
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="headerOption"
                      onClick={() => navigate("/payment")}
                    >
                      Payment
                    </span>
                  </>
                )}
              </>
            )}
            <span className="headerOption" onClick={() => navigate("/contact")}>
              Contact
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
