import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./Register.scss";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useRef } from "react";

const Register = () => {
  let navigate = useNavigate();
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleRegister = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    auth.languageCode = auth.useDeviceLanguage();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          payment: false,
          oneWeekFreeTrial: new Date()
            .setDate(new Date().getDate() + 7)
            .toISOString(),
        });
        const user = result.user;
        navigate("/dashboard");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        Swal.fire(
          "Error " + errorCode,
          errorMessage + " There was an error with the email: " + email,
          "error"
        );
      });
  };

  const submitRegistration = () => {
    createUserWithEmailAndPassword(
      getAuth(),
      emailRef.current.value,
      passwordRef.current.value
    )
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        updateProfile(getAuth().currentUser, {
          displayName: nameRef.current.value,
        })
          .then(async () => {
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 7);
            const futureISOString = futureDate.toISOString();

            await setDoc(doc(db, "users", getAuth().currentUser.uid), {
              payment: false,
              oneWeekFreeTrial: futureISOString,
            });
            navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };
  return (
    <div className="registerContainer">
      <span className="registerTitle">Register</span>
      <span className="disclaimerText">
        Upon registering, you will receive a one-week trial.
      </span>
      <div className="registerButton" onClick={() => handleRegister()}>
        <img
          src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
          alt="Google Icon"
          className="googleImage"
        />
        <span className="registerButtonText">Continue with Google</span>
      </div>
      <span className="orText">or</span>
      <input ref={nameRef} type="text" placeholder="Name" />
      <input ref={emailRef} type="email" placeholder="Email" />
      <input ref={passwordRef} type="password" placeholder="Password" />
      <button onClick={() => submitRegistration()}>Register</button>
      <span className="haveAccount">
        Have an account? <a href="/login">Login</a>
      </span>
    </div>
  );
};

export default Register;
