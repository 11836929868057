import { useState } from "react";
import "./Payment.scss";
import "./Payment.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

export default function Payment() {
  let navigate = useNavigate();
  const auth = getAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [toggle, setToggle] = useState();
  const [toggleTwo, setToggleTwo] = useState();
  const [toggleThree, setToggleThree] = useState();

  const createSubscription = (type) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      type: type,
      email: getAuth().currentUser.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://aicruit-api-a8f6c1db1758.herokuapp.com/create-subscription/",
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        const res = JSON.parse(result);
        if (res.status === "Success!") {
          const clientSecret = res["client_secret"];

          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: getAuth().currentUser.displayName,
                email: getAuth().currentUser.email,
              },
            },
          });

          const date = new Date();

          if (type === "1 year") {
            date.setFullYear(date.getFullYear() + 1);
          } else if (type === "6 months") {
            // Add 6 months, considering edge cases for changing years
            let newMonth = date.getMonth() + 6;
            let newYear = date.getFullYear();

            if (newMonth >= 12) {
              newYear += Math.floor(newMonth / 12);
              newMonth %= 12;
            }

            date.setFullYear(newYear);
            date.setMonth(newMonth);
          } else {
            date.setMonth(date.getMonth() + 1);
          }

          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.error(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              Swal.fire(
                "Success!",
                "Your subscription was successfully created",
                "success"
              );
              await setDoc(doc(db, "users", auth.currentUser.uid), {
                payment: true,
                paymentDate: date,
                plan: type,
              });
            }
          }

          navigate("/");
        }
      })
      .catch(() =>
        Swal.fire(
          "Error!",
          "There was an error when purchasing your option. Please try again!",
          "error"
        )
      );
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <Header />
      <section className="paymentContainer">
        <h1 className="pricingTitle">Our Pricing</h1>
        <div>
          <form
            onClick={() => {
              setToggle(true); // Set the 'toggle' state to true when clicked
              setToggleTwo(false); // Set other toggles to false if needed
              setToggleThree(false);
            }}
            className={`paymentForm ${toggle ? "active" : ""}`}
          >
            <div className="innerForm">
              <div className="row">
                <h2 className="planName">1 Month</h2>
              </div>
              <h3 className="planPrice">
                <span className="dollar">$</span> 20.00
              </h3>
              <span className="planDetails">
                <li className="planDetail">Valid for 1 month</li>
                <li className="planDetail">
                  Access to state-of-the-art AI interviewer
                </li>
                <li className="spPlanDetail">
                  Shortlist candidates using Resume Screener
                </li>
              </span>
              {toggle ? <CardElement options={CARD_ELEMENT_OPTIONS} /> : <></>}
              <button
                className="purchaseBtn"
                onClick={(e) => {
                  e.preventDefault();
                  createSubscription("1 month");
                }}
              >
                Purchase
              </button>
            </div>
          </form>
          <form
            onClick={() => {
              setToggleTwo(true); // Set the 'toggleTwo' state to true when clicked
              setToggle(false); // Set other toggles to false if needed
              setToggleThree(false);
            }}
            className={`paymentForm ${toggleTwo ? "active" : ""}`}
          >
            <div className="innerForm">
              <div className="row">
                <h2 className="planName">6 Months</h2>
                <div className="discount">
                  <span className="discountText">Save 10%</span>
                </div>
              </div>
              <h3 className="planPrice">
                <span className="dollar">$</span> 110.00
              </h3>
              <span className="planDetails">
                <li className="planDetail">Valid for 6 months</li>
                <li className="planDetail">
                  Access to state-of-the-art AI interviewer
                </li>
                <li className="spPlanDetail">
                  Shortlist candidates using Resume Screener
                </li>
              </span>
              {toggleTwo ? (
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              ) : (
                <></>
              )}
              <button
                className="purchaseBtn"
                onClick={(e) => {
                  e.preventDefault();
                  createSubscription("6 months");
                }}
              >
                Purchase
              </button>
            </div>
          </form>
          <form
            onClick={() => {
              setToggleThree(true); // Set the 'toggleThree' state to true when clicked
              setToggle(false); // Set other toggles to false if needed
              setToggleTwo(false);
            }}
            className={`paymentForm ${toggleThree ? "active" : ""}`}
          >
            <div className="innerForm">
              <div className="row">
                <h2 className="planName">Annual</h2>
                <div className="discount">
                  <span className="discountText">Save 20%</span>
                </div>
              </div>

              <h3 className="planPrice">
                <span className="dollar">$</span> 200.00
              </h3>
              <span className="planDetails">
                <li className="planDetail">Valid for 1 year</li>
                <li className="planDetail">
                  Access to state-of-the-art AI interviewer
                </li>
                <li className="spPlanDetail">
                  Shortlist candidates using Resume Screener
                </li>
              </span>
              {toggleThree ? (
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              ) : (
                <></>
              )}

              <button
                className="purchaseBtn"
                onClick={(e) => {
                  e.preventDefault();
                  createSubscription("1 year");
                }}
              >
                Purchase
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
