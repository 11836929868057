import { useNavigate } from "react-router-dom";
import "./Header.scss";
import { getAuth, signOut } from "firebase/auth";

const Header = () => {
  let navigate = useNavigate();

  return (
    <div className="header" id="joinWaitlist">
      <div className="leftHeader">AIcruit</div>
      <div className="rightHeader">
        <span className="headerOption" onClick={() => navigate("/")}>
          Home
        </span>
        <span
          className="headerOption"
          onClick={() => {
            signOut(getAuth());
            navigate("/");
          }}
        >
          Sign Out
        </span>
        <span className="headerOption" onClick={() => navigate("/contact")}>
          Contact
        </span>
      </div>
    </div>
  );
};

export default Header;
