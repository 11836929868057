import { useSearchParams } from "react-router-dom";
import "./Interview.scss";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FaRegClock } from "react-icons/fa6";
import { HiOutlineLightBulb } from "react-icons/hi";
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const Interview = () => {
  // TODO: implement plagiarism stuff in the useEffect
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const code = searchParams.get("code");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [question, setQuestion] = useState("Question Generating...");
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [remainingTime, setRemainingTime] = useState(
    () => parseInt(localStorage.getItem("remainingTime")) || 45 * 60
  );

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const correctQuestion = (question) => {
    if (question === "done") {
      Swal.fire(
        "Congratulations!",
        "The interview is finished! You may now close this browser tab.",
        "success"
      );
    } else {
      Swal.fire("Success!", "Your code worked!", "success");

      setQuestion(question);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    const interviewCode = {
      code: code,
    };
    formData.append("interviewData", JSON.stringify(interviewCode));

    fetch("https://aicruit-api-a8f6c1db1758.herokuapp.com/submit", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        res.status === "Success!"
          ? correctQuestion(res.message)
          : Swal.fire(res.status, res.message, "error");
        setShowModal(false);
      })
      .catch(() => {
        Swal.fire(
          "Error!",
          "There was an error while submitting the file. Please try again",
          "error"
        );
      });
    setSubmitted(true);
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      code: code,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://aicruit-api-a8f6c1db1758.herokuapp.com/join-interview",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        if (res.status === "Error!") {
          Swal.fire("Error!", res.message, "error");
        } else {
          setLoading(false);
          Swal.fire(
            "Success!",
            "Hello my name is AIcruit and I will conduct the technical portion of your interview. Try to comment your code to explain the thought process and put the question in the first line of your code in comments. Write your code in the appropriate file extension (e.g., python - .py files, plain text - .txt files). CLOSE ANY OPEN BROWSER TABS OR AI APPLICATIONS.",
            "success"
          );
          setTimeout(function () {
            setQuestion(res.message.question);
          }, 5000);
        }
      })
      .catch(() =>
        Swal.fire(
          "Error!",
          "There was an error joining the interview. Please referesh the screen.",
          "error"
        )
      );
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        const updatedTime = prevTime - 1;
        localStorage.setItem("remainingTime", String(updatedTime));
        return updatedTime;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [code]);

  useEffect(() => {
    if (remainingTime <= 0) {
      localStorage.removeItem("remainingTime");
      setShowModal(true);

      if (submitted) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const docRef = doc(db, "codes", "1");
        const docSnap = getDoc(docRef);
        let secret_phrase = "";
        if (docSnap.exists()) {
          secret_phrase = docSnap.data()["codes"]["exit"];
        }

        var raw = JSON.stringify({
          interview_code: code,
          secret_phrase: secret_phrase,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://aicruit-api-a8f6c1db1758.herokuapp.com/end-interview",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            const res = JSON.parse(result);
            res.status === "Success!"
              ? Swal.fire(
                  "Interview Ended",
                  "The interview has now ended.",
                  "success"
                )
              : Swal.fire(res.status, res.message, "error");
          })
          .catch(() =>
            Swal.fire(
              "Incorrect Input!",
              "An incorrect input has been provided. Please try again!",
              "error"
            )
          );
      }
    }
  }, [remainingTime, code, submitted]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  const getHint = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      question: question,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://aicruit-api-a8f6c1db1758.herokuapp.com/hint", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        Swal.fire("Hint", res.message, "info");
      })
      .catch(() =>
        Swal.fire(
          "Error!",
          "There was an error when providing the hint. Please try again!",
          "error"
        )
      );
  };

  return (
    <div>
      {loading && (
        <div className="loadingContainer">
          <ReactLoading
            type={"bubbles"}
            color={"#ff8c00"}
            height={667}
            width={375}
          />
        </div>
      )}
      <div className="timer">
        <FaRegClock style={{ marginRight: "1rem" }} />
        <span>{formattedMinutes}</span>:<span>{formattedSeconds}</span>
      </div>
      <div className="candidateQuestionContainer">
        <span className="candidateQuestion">{question}</span>
        <div className="buttonContainer">
          {!isSelected ? (
            <input type="file" name="file" onChange={handleFileChange} />
          ) : (
            <div className="button" onClick={() => handleSubmit()}>
              <span className="buttonText">Submit</span>
            </div>
          )}
          <div className="hintButton" onClick={() => getHint()}>
            <HiOutlineLightBulb />
            <span className="hintText">Get Hint</span>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modalContainer">
            {!isSelected ? (
              <input type="file" name="file" onChange={handleFileChange} />
            ) : (
              <div
                className="button"
                onClick={() => {
                  closeModal();
                  handleSubmit();
                }}
              >
                <span className="buttonText">Submit</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Interview;
